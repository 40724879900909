import React from "react";
import PropTypes from "prop-types";
import SmartText from "../../utils/TextHandler";
import SmartImage from "../../utils/ImageHandler";
import Image from "../Global/Image";

const Banner = (props) => {
  const {
    title,
    description,
    image,
    button,
    link,
    colour,
    background,
    text,
    accent,
    supporting,
    logo,
  } = props;

  if (title !== "" || image.filename) {
    return (
      <section
        className="brand-background-black brand-background-cover"
        style={
          image && {
            backgroundImage: `url(${SmartImage(image.filename, "1920x1080")})`,
          }
        }
      >
        <div className="container">
          <div className="ratio ratio-hero">
            <div className="d-flex justify-content-center align-items-center brand-text-white text-center">
              {logo ? (
                <div className="h-100">
                  <Image
                    src={logo.filename}
                    lg="0x700"
                    md="0x400"
                    sm="0x300"
                    className="h-100"
                  />
                </div>
              ) : (
                <div>
                  <h2
                    className={`brand-font brand-text-shadow ${
                      description ? "mb-5" : ""
                    }`}
                  >
                    {title}
                  </h2>
                  {description && <SmartText>{description}</SmartText>}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
  return null;
};

export default Banner;

Banner.propTypes = {
  settings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Banner.defaultProps = {
  settings: null,
};

/*
    <section className="py-5 text-center brand-background-pink brand-text-white">
      <div className="container py-5">
        {title && (
          <h2 className={`brand-font ${description ? "mb-5" : ""}`}>{title}</h2>
        )}
        {description && <SmartText>{description}</SmartText>}
      </div>
    </section>



    <section className="brand-background-black brand-text-white">
      <div className="ratio ratio-16x4-5 border">
        <div className="d-flex justify-content-center align-items-center">
          <div className="container text-center">
            {title && (
              <h2 className={`brand-font ${description ? "mb-5" : ""}`}>
                {title}
              </h2>
            )}
            {description && <SmartText>{description}</SmartText>}
          </div>
        </div>
      </div>
    </section>


*/
